import { createSlice } from '@reduxjs/toolkit'
import { getShippings, saveShipping, createShipping, deleteShipping } from './shippingActions'

const initialState = {
  shippings: [],
  loading: false,
  error: false,
  loaded: false,
  saving: false,
  saved: false,
  savedShipping: {},
  deleting: false,
  deleted: false,
}

/*function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}*/

const shippingsSlice = createSlice({
  name: 'shippings',
  initialState,
  reducers: {
    clear: (state) => {
      state.loading = false
      state.loaded = false

      state.saving = false
      state.error = false
      state.saved = false
      state.savedShipping = {}

      state.deleting = false
      state.deleted = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getShippings.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loaded = true
        state.error = null
        state.shippings = payload
      })
      .addCase(getShippings.pending, (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
      })
      .addCase(getShippings.rejected, (state, { payload }) => {
        state.loading = false
        state.loaded = false
        state.error = payload
      })
      .addCase(saveShipping.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(saveShipping.fulfilled, (state, { payload }) => {
        state.error = null
        state.shippings[state.shippings.findIndex((p) => p.id === payload.id)] = payload
        state.savedShipping = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveShipping.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(createShipping.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(createShipping.fulfilled, (state, { payload }) => {
        state.error = null
        state.savedShipping = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createShipping.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(deleteShipping.pending, (state) => {
        state.deleting = true
        state.error = null
        state.deleted = false
      })
      .addCase(deleteShipping.fulfilled, (state) => {
        state.error = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteShipping.rejected, (state, { payload }) => {
        state.deleting = false
        state.error = payload
        state.deleted = false
      })
  },
})

export const { clear } = shippingsSlice.actions
export default shippingsSlice.reducer
