import { createSlice } from '@reduxjs/toolkit'
import {
  saveAttachment,
  createAttachment,
  deleteAttachment,
  getAttachments,
} from './attachmentActions'

const initialState = {
  error: false,
  saving: false,
  saved: false,
  loading: false,
  loaded: false,
  savedAttachment: {},
  deleting: false,
  deleted: false,
  deletingError: false,
  attachments: [],
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}

const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {
    clear: (state) => {
      state.saved = false
      state.saving = false
      state.error = false
      state.savedAttachment = {}
      state.deleting = false
      state.deleted = false
      state.deletingError = false
      state.loaded = false
      state.attachments = []
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(saveAttachment.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(saveAttachment.fulfilled, (state, { payload }) => {
        state.error = null
        state.savedAttachment = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveAttachment.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(createAttachment.pending, (state) => {
        state.saving = true
        state.savingError = null
        state.saved = false
      })
      .addCase(createAttachment.fulfilled, (state, { payload }) => {
        state.savingError = null
        state.savedAttachment = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createAttachment.rejected, (state, { payload }) => {
        state.saving = false
        state.savingError = payload
        state.saved = false
      })
      .addCase(deleteAttachment.pending, (state) => {
        state.deleting = true
        state.deletingError = null
        state.deleted = false
      })
      .addCase(deleteAttachment.fulfilled, (state) => {
        state.deletingError = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteAttachment.rejected, (state, { payload }) => {
        state.deleting = false
        state.deletingError = payload
        state.deleted = false
      })
      .addCase(getAttachments.pending, (state) => {
        state.loading = true
        state.loaded = false
      })
      .addCase(getAttachments.fulfilled, (state, { payload }) => {
        state.loading = false
        state.attachments = payload
        state.loaded = true
      })
      .addCase(getAttachments.rejected, (state, { payload }) => {
        state.loading = false
        state.loaded = false
      })
      .addMatcher(
        isRejectedAction,
        // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
        (state, { payload }) => {
          state.loading = false
          state.error = payload
        },
      )
  },
})

export const { clear } = attachmentsSlice.actions
export default attachmentsSlice.reducer
