import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getNewsletters = createAsyncThunk(
  'newsletters/get',
  async (manager, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const url = manager ? '/api/newsletters/' : '/api/news/'
      const { data } = await axios.get(url)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const saveNewsletter = createAsyncThunk(
  'newsletter/save',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.put(`/api/newsletters/${t.id}/`, t)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const createNewsletter = createAsyncThunk(
  'newsletter/create',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.post('/api/newsletters/', t)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteNewsletter = createAsyncThunk(
  'newsletter/delete',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.delete(`/api/newsletters/${t.id}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
