import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getStoreItems = createAsyncThunk('storeitems/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/storeitems/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveStoreItem = createAsyncThunk('storeitem/save', async (t, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    // const config = { headers: { 'content-type': 'multipart/form-data' } }
    const { data } = await axios.put(`/api/storeitems/${t.id}/`, t)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const createStoreItem = createAsyncThunk(
  'storeitem/create',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.post('/api/storeitem/create/', t)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteStoreItem = createAsyncThunk(
  'storeitem/delete',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.delete(`/api/storeitems/${t.id}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
