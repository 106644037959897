import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getShippings = createAsyncThunk('shippings/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/shippings/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveShipping = createAsyncThunk('shipping/save', async (t, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    const { data } = await axios.put(`/api/shippings/${t.id}/`, t, config)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const createShipping = createAsyncThunk(
  'shipping/create',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.post('/api/shipping/create/', t)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteShipping = createAsyncThunk(
  'shipping/delete',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.delete(`/api/shippings/${t.id}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
