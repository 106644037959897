import { createSlice } from '@reduxjs/toolkit'
import {
  getNewsletters,
  saveNewsletter,
  createNewsletter,
  deleteNewsletter,
} from './newsletterActions'

const initialState = {
  newsletters: [],
  loading: false,
  error: false,
  loaded: false,
  saving: false,
  saved: false,
  savedNewsletter: {},
  deleting: false,
  deleted: false,
}

/*function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}*/

const newslettersSlice = createSlice({
  name: 'newsletters',
  initialState,
  reducers: {
    clear: (state) => {
      state.loading = false
      state.loaded = false

      state.saving = false
      state.error = false
      state.saved = false
      state.savedNewsletter = {}

      state.deleting = false
      state.deleted = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getNewsletters.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loaded = true
        state.error = null
        state.newsletters = payload
      })
      .addCase(getNewsletters.pending, (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
      })
      .addCase(getNewsletters.rejected, (state, { payload }) => {
        state.loading = false
        state.loaded = false
        state.error = payload
      })
      .addCase(saveNewsletter.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(saveNewsletter.fulfilled, (state, { payload }) => {
        state.error = null
        state.newsletters[state.newsletters.findIndex((p) => p.id === payload.id)] = payload
        state.savedNewsletter = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveNewsletter.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(createNewsletter.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(createNewsletter.fulfilled, (state, { payload }) => {
        state.error = null
        state.savedNewsletter = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createNewsletter.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(deleteNewsletter.pending, (state) => {
        state.deleting = true
        state.error = null
        state.deleted = false
      })
      .addCase(deleteNewsletter.fulfilled, (state) => {
        state.error = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteNewsletter.rejected, (state, { payload }) => {
        state.deleting = false
        state.error = payload
        state.deleted = false
      })
  },
})

export const { clear } = newslettersSlice.actions
export default newslettersSlice.reducer
