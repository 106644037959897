import { createSlice } from '@reduxjs/toolkit'
import { getStoreItems, saveStoreItem, createStoreItem, deleteStoreItem } from './storeItemActions'

const initialState = {
  storeItems: [],
  loading: false,
  error: false,
  loaded: false,
  saving: false,
  saved: false,
  savedStoreItem: {},
  deleting: false,
  deleted: false,
}

/*function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}*/

const storeItemsSlice = createSlice({
  name: 'storeItems',
  initialState,
  reducers: {
    clear: (state) => {
      state.loading = false
      state.loaded = false

      state.saving = false
      state.error = false
      state.saved = false
      state.savedStoreItem = {}

      state.deleting = false
      state.deleted = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getStoreItems.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loaded = true
        state.error = null
        state.storeItems = payload
      })
      .addCase(getStoreItems.pending, (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
      })
      .addCase(getStoreItems.rejected, (state, { payload }) => {
        state.loading = false
        state.loaded = false
        state.error = payload
      })
      .addCase(saveStoreItem.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(saveStoreItem.fulfilled, (state, { payload }) => {
        state.error = null
        state.storeItems[state.storeItems.findIndex((p) => p.id === payload.id)] = payload
        state.savedStoreItem = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveStoreItem.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(createStoreItem.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(createStoreItem.fulfilled, (state, { payload }) => {
        state.error = null
        state.savedStoreItem = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createStoreItem.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(deleteStoreItem.pending, (state) => {
        state.deleting = true
        state.error = null
        state.deleted = false
      })
      .addCase(deleteStoreItem.fulfilled, (state) => {
        state.error = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteStoreItem.rejected, (state, { payload }) => {
        state.deleting = false
        state.error = payload
        state.deleted = false
      })
  },
})

export const { clear } = storeItemsSlice.actions
export default storeItemsSlice.reducer
