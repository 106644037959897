import { createSlice } from '@reduxjs/toolkit'
import { getDealers, saveDealer, createDealer, deleteDealer } from './dealerActions'

const initialState = {
  dealers: [],
  loading: false,
  error: false,
  loaded: false,
  saving: false,
  saved: false,
  savedDealer: {},
  deleting: false,
  deleted: false,
}

/*function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}*/

const dealersSlice = createSlice({
  name: 'dealers',
  initialState,
  reducers: {
    clear: (state) => {
      state.loading = false
      state.loaded = false

      state.saving = false
      state.error = false
      state.saved = false
      state.savedDealer = {}

      state.deleting = false
      state.deleted = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getDealers.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loaded = true
        state.error = null
        state.dealers = payload
      })
      .addCase(getDealers.pending, (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
      })
      .addCase(getDealers.rejected, (state, { payload }) => {
        state.loading = false
        state.loaded = false
        state.error = payload
      })
      .addCase(saveDealer.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(saveDealer.fulfilled, (state, { payload }) => {
        state.error = null
        state.savedDealer = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveDealer.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(createDealer.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(createDealer.fulfilled, (state, { payload }) => {
        state.error = null
        state.savedDealer = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createDealer.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(deleteDealer.pending, (state) => {
        state.deleting = true
        state.error = null
        state.deleted = false
      })
      .addCase(deleteDealer.fulfilled, (state) => {
        state.error = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteDealer.rejected, (state, { payload }) => {
        state.deleting = false
        state.error = payload
        state.deleted = false
      })
  },
})

export const { clear } = dealersSlice.actions
export default dealersSlice.reducer
