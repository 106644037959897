import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getProducts = createAsyncThunk('products/get', async (dealer, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const url = dealer ? '/api/dp/' : '/api/products/'
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveProduct = createAsyncThunk('product/save', async (t, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.put(`/api/products/${t.id}/`, t)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const createProduct = createAsyncThunk('product/create', async (t, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.post('/api/products/', t)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const deleteProduct = createAsyncThunk('product/delete', async (t, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.delete(`/api/products/${t.id}/`)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})
