import { configureStore } from '@reduxjs/toolkit'
import userReducer from './features/user/userSlice'
import regRequestReducer from './features/regrequest/regRequestSlice'
import dealerReducer from './features/dealer/dealerSlice'
import attachmentReducer from './features/attachment/attachmentSlice'
import productReducer from './features/product/productSlice'
import orderReducer from './features/order/orderSlice'
import newsletterReducer from './features/newsletter/newsletterSlice'
import dealerGroupReducer from './features/dealerGroup/dealerGroupSlice'
import shippingReducer from './features/shipping/shippingSlice'
import variableReducer from './features/variable/variableSlice'
import storeItemReducer from './features/store/storeItemSlice'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  sidebarUnfoldable: null,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = configureStore({
  reducer: {
    ui: changeState,
    user: userReducer,
    regRequests: regRequestReducer,
    dealers: dealerReducer,
    attachments: attachmentReducer,
    products: productReducer,
    orders: orderReducer,
    newsletters: newsletterReducer,
    dealerGroups: dealerGroupReducer,
    shippings: shippingReducer,
    variables: variableReducer,
    storeItems: storeItemReducer,
  },
  /*middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware().concat(createLogger())
  ]*/
})

export default store
