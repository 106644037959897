import { createSlice } from '@reduxjs/toolkit'
import { getVariables, saveVariable, createVariable, deleteVariable } from './variableActions'

const initialState = {
  variables: [],
  loading: false,
  error: false,
  loaded: false,
  saving: false,
  saved: false,
  savedVariable: {},
  deleting: false,
  deleted: false,
}

/*function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}*/

const variablesSlice = createSlice({
  name: 'variables',
  initialState,
  reducers: {
    clear: (state) => {
      state.loading = false
      state.loaded = false

      state.saving = false
      state.error = false
      state.saved = false
      state.savedVariable = {}

      state.deleting = false
      state.deleted = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getVariables.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loaded = true
        state.error = null
        state.variables = payload
      })
      .addCase(getVariables.pending, (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
      })
      .addCase(getVariables.rejected, (state, { payload }) => {
        state.loading = false
        state.loaded = false
        state.error = payload
      })
      .addCase(saveVariable.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(saveVariable.fulfilled, (state, { payload }) => {
        state.error = null
        state.variables[state.variables.findIndex((p) => p.id === payload.id)] = payload
        state.savedVariable = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveVariable.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(createVariable.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(createVariable.fulfilled, (state, { payload }) => {
        state.error = null
        state.savedVariable = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createVariable.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(deleteVariable.pending, (state) => {
        state.deleting = true
        state.error = null
        state.deleted = false
      })
      .addCase(deleteVariable.fulfilled, (state) => {
        state.error = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteVariable.rejected, (state, { payload }) => {
        state.deleting = false
        state.error = payload
        state.deleted = false
      })
  },
})

export const { clear } = variablesSlice.actions
export default variablesSlice.reducer
