import { createSlice } from '@reduxjs/toolkit'
import { getProducts, saveProduct, createProduct, deleteProduct } from './productActions'

const initialState = {
  products: [],
  loading: false,
  error: false,
  loaded: false,
  saving: false,
  saved: false,
  savedProduct: {},
  deleting: false,
  deleted: false,
}

/*function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}*/

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    clear: (state) => {
      state.loading = false
      state.loaded = false

      state.saving = false
      state.error = false
      state.saved = false
      state.savedProduct = {}

      state.deleting = false
      state.deleted = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loaded = true
        state.error = null
        state.products = payload
      })
      .addCase(getProducts.pending, (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
      })
      .addCase(getProducts.rejected, (state, { payload }) => {
        state.loading = false
        state.loaded = false
        state.error = payload
      })
      .addCase(saveProduct.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(saveProduct.fulfilled, (state, { payload }) => {
        state.error = null
        state.products[state.products.findIndex((p) => p.id === payload.id)] = payload
        state.savedProduct = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveProduct.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(createProduct.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(createProduct.fulfilled, (state, { payload }) => {
        state.error = null
        state.savedProduct = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createProduct.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(deleteProduct.pending, (state) => {
        state.deleting = true
        state.error = null
        state.deleted = false
      })
      .addCase(deleteProduct.fulfilled, (state) => {
        state.error = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteProduct.rejected, (state, { payload }) => {
        state.deleting = false
        state.error = payload
        state.deleted = false
      })
  },
})

export const { clear } = productsSlice.actions
export default productsSlice.reducer
