import { createSlice } from '@reduxjs/toolkit'
import {
  getDealerGroups,
  saveDealerGroup,
  createDealerGroup,
  deleteDealerGroup,
} from './dealerGroupActions'

const initialState = {
  dealerGroups: [],
  loading: false,
  error: false,
  loaded: false,
  saving: false,
  saved: false,
  savedDealerGroup: {},
  deleting: false,
  deleted: false,
}

/*function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}*/

const dealerGroupsSlice = createSlice({
  name: 'dealerGroups',
  initialState,
  reducers: {
    clear: (state) => {
      state.loading = false
      state.loaded = false

      state.saving = false
      state.error = false
      state.saved = false
      state.savedDealerGroup = {}

      state.deleting = false
      state.deleted = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getDealerGroups.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loaded = true
        state.error = null
        state.dealerGroups = payload
      })
      .addCase(getDealerGroups.pending, (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
      })
      .addCase(getDealerGroups.rejected, (state, { payload }) => {
        state.loading = false
        state.loaded = false
        state.error = payload
      })
      .addCase(saveDealerGroup.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(saveDealerGroup.fulfilled, (state, { payload }) => {
        state.error = null
        state.dealerGroups[state.dealerGroups.findIndex((p) => p.id === payload.id)] = payload
        state.savedDealerGroup = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveDealerGroup.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(createDealerGroup.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(createDealerGroup.fulfilled, (state, { payload }) => {
        state.error = null
        state.savedDealerGroup = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createDealerGroup.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(deleteDealerGroup.pending, (state) => {
        state.deleting = true
        state.error = null
        state.deleted = false
      })
      .addCase(deleteDealerGroup.fulfilled, (state) => {
        state.error = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteDealerGroup.rejected, (state, { payload }) => {
        state.deleting = false
        state.error = payload
        state.deleted = false
      })
  },
})

export const { clear } = dealerGroupsSlice.actions
export default dealerGroupsSlice.reducer
