import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getOrders = createAsyncThunk('orders/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/orders/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveOrder = createAsyncThunk('order/save', async (t, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    const { data } = await axios.put(`/api/orders/${t.id}/`, t, config)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const createOrder = createAsyncThunk('order/create', async (t, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.post('/api/orders/', t)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const deleteOrder = createAsyncThunk('order/delete', async (t, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.delete(`/api/orders/${t.id}/`)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const addToCart = createAsyncThunk('order/addtocart', async (item, { rejectWithValue }) => {
  const cartItems = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : []
  cartItems.push(item)
  try {
    localStorage.setItem('cart', JSON.stringify(cartItems))
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const removeFromCart = createAsyncThunk(
  'order/removefromcart',
  async (index, { rejectWithValue }) => {
    const cartItems = JSON.parse(localStorage.getItem('cart'))
    cartItems.splice(index, 1)
    try {
      localStorage.setItem('cart', JSON.stringify(cartItems))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const emptyCart = createAsyncThunk('order/emptycart', async (...args) => {
  try {
    localStorage.setItem('cart', JSON.stringify([]))
  } catch (error) {
    const { rejectWithValue } = args[1]
    return rejectWithValue(error)
  }
})

export const getCart = createAsyncThunk('order/getcart', async (...args) => {
  try {
    const data = JSON.parse(localStorage.getItem('cart'))
    if (!data) return []
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any

    return rejectWithValue(error)
  }
})
