import { createSlice } from '@reduxjs/toolkit'
import {
  getRegRequests,
  saveRegRequest,
  createRegRequest,
  deleteRegRequest,
} from './regRequestActions'

const initialState = {
  regRequests: [],
  loading: false,
  error: false,
  loaded: false,
  saving: false,
  saved: false,
  savedRegRequest: {},
  deleting: false,
  deleted: false,
}

/*function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}*/

const regRequestsSlice = createSlice({
  name: 'regRequests',
  initialState,
  reducers: {
    clear: (state) => {
      state.loading = false
      state.loaded = false

      state.saving = false
      state.error = false
      state.saved = false
      state.savedRegRequest = {}

      state.deleting = false
      state.deleted = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getRegRequests.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loaded = true
        state.error = null
        state.regRequests = payload
      })
      .addCase(getRegRequests.pending, (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
      })
      .addCase(getRegRequests.rejected, (state, { payload }) => {
        state.loading = false
        state.loaded = false
        state.error = payload
      })
      .addCase(saveRegRequest.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(saveRegRequest.fulfilled, (state, { payload }) => {
        state.error = null
        state.regRequests[state.regRequests.findIndex((p) => p.id === payload.id)] = payload
        state.savedRegRequest = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveRegRequest.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(createRegRequest.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(createRegRequest.fulfilled, (state, { payload }) => {
        state.error = null
        state.savedRegRequest = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createRegRequest.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(deleteRegRequest.pending, (state) => {
        state.deleting = true
        state.error = null
        state.deleted = false
      })
      .addCase(deleteRegRequest.fulfilled, (state) => {
        state.error = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteRegRequest.rejected, (state, { payload }) => {
        state.deleting = false
        state.error = payload
        state.deleted = false
      })
  },
})

export const { clear } = regRequestsSlice.actions
export default regRequestsSlice.reducer
